import { graphql, useStaticQuery } from 'gatsby'

const useNoticias = () => {
    const resultado = useStaticQuery(graphql`
    {
      allStrapiNoticias(
        sort: {order: DESC, fields: strapiId}
        filter: {categoria_noticia: {id: {eq: 1}}}
      ) {
        nodes {
          id
          strapiId
          nombre
          contenido
          fecha
          imagenes {
            url
          }
          created_at
        }
      }
    }
  `)
  return resultado.allStrapiNoticias.nodes
   
}

export default useNoticias