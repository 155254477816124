import * as React from 'react'
import Layout from '../components/layout'
import { Container, Row, Col } from 'react-bootstrap'
import { ListadoNoticias } from '../components/noticias/listadoNoticias'
import { Titulo } from '../components/titulo'


export default function noticias () {
    
    return (
        <Layout>
            <Titulo title="Noticias colegio" />
            <Container className="mb-5 ">
                <Row>
                    <Col md={12}><ListadoNoticias /></Col>
                </Row>
            </Container>
        </Layout>
    )
}