import { types } from "../types/types";

export const activarNoticia = ( noticia ) => ({
    type: types.activarNoticia,
    payload: noticia
});

export const obtenerNoticias = ( noticias ) => ({
    type: types.obtenerNoticias,
    payload: noticias
});