import React, { useEffect } from 'react'
import { Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { activarNoticia, obtenerNoticias } from '../../action/noticia'
import useNoticias from '../../hooks/useNoticias'
import { Noticia } from './noticia'

export const ListadoNoticias = () => {
    const dispatch = useDispatch();
    const noticias = useNoticias();

    useEffect(() => {
        dispatch(obtenerNoticias(noticias))
        dispatch(activarNoticia(noticias[0]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [noticias])
    
    return (
        <Row>
            {noticias && noticias.map(noticia => (
                <Noticia
                    key={ noticia.id }
                    noticia={ noticia }
                />
            ))}
        </Row>
    )
}
