import React from 'react'
import { Link } from 'gatsby'
import urlSlug from 'url-slug'
import { Card, Col, Image, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { activarNoticia } from '../../action/noticia'

export const Noticia = ({noticia}) => {
    const dispatch = useDispatch()
    const { contenido, nombre, fecha, imagenes, id } = noticia
    const imagenMostrar = imagenes.slice(0,4)

    return (
        <Col xs={12} md={6} className="mt-2">
            <Card bg="dark">
                <Card.Body>
                    <Row>
                        <Col xs ={12} md={8} lg={8}>
                            <Card.Title>
                                {nombre}
                            </Card.Title>
                        </Col>
                        <Col xs ={12} md={4} lg={4}><p className="text-end">{fecha}</p></Col>
                    </Row>
                    <p>{`${contenido.substr(0,220)} ...`}</p>
                    <Row>
                        {imagenes 
                            ? imagenMostrar.map( imagen => (
                                <Col xs={3} md={3} lg={3} className="d-flex justify-content-center align-items-center"  key={imagen.url}>
                                    <Image
                                        src={`${process.env.GATSBY_API_URL}${imagen.url}`}
                                        alt="slide"
                                        rounded
                                        className="img-fluid"
                                    />
                                </Col>
                            ))
                            : null
                        }
                    </Row>
                    <Row className="mt-2 ">
                        <Col xs={0} md={7}></Col>
                        <Col xs={12} md={5} >
                            <button 
                                onClick={() => dispatch(activarNoticia(noticia)) } 
                                className="btn btn-outline-info btn-block"
                            >
                                <Link to={urlSlug(id)} style={{color:'inherit', textDecoration:'inherit'}}>Ver más... </Link>
                            </button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )
}
